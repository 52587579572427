.buying-card-wrapper {
  width: auto;
  height: auto;
  border-radius: 0.6rem;

  margin: 1em 0.2em;
}

.buying-card {
  margin: 0em 1em;
}

.sub-module {
  margin-top: 0.8em;
  width: 20em;

  & > span {
    font-size: 14px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: #fff;
    line-height: 1.5;
    opacity: 0.6;
  }
}

.image-sub-module-content {
  margin-top: 0.8em;
  width: 20em;
  height: 23.1em;
  border-radius: 6px;
  line-height: unset;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.4);
  position: relative;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  & > .ant-image {
    width: 20em;
    height: 11.7em;
    display: flex;
    background: rgba(0, 0, 0, 0.3);
    border-radius: 6px 6px 0px 0px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);

    & > img {
      margin: auto;
      width: auto;
      height: auto;
      max-width: 100%;
      max-height: 100%;
    }
  }

  & .image-module-description {
    width: 20em;
    height: 11.4em;
    padding: 1em 1.1em 2.2em 1.1em;
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.35) 0%, rgba(0, 0, 0, 0.65) 100%);
    box-shadow: inset 0px 0px 20px 0px rgba(255, 255, 255, 0.2);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);
    border-bottom-left-radius: 0.6em;
    border-bottom-right-radius: 0.6em;

    & > :first-child {
      font-size: 14px;
      // font-family: Noto Sans;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 19px;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      margin-bottom: 0.923em;
    }
  }
}

.content-sub-module-content {
  margin-top: 0.8em;
  width: 20em;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 6px;
  padding: 1.2em;

  & .content-module-description {
    width: 100%;
    text-align: center;

    & > :first-child {
      display: block;
      font-size: 15px;
      // font-family: Noto Sans;
      font-weight: 400;
      color: #fff;
      line-height: 1.5;
      margin-bottom: 1em;

      justify-content: space-between;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;

      & > :first-child {
        margin-right: 0.7em;
      }
    }

    & > :last-child {
      text-align: right;
      display: flex;
      align-items: center;
    }
  }
}

.sub-module-button,
.sub-module-button:hover,
.sub-module-button:focus,
.sub-module-button:active {
  font-size: inherit !important;
  padding: 0 !important;
  display: flex;
  margin: auto;
  width: 17.2em;
  height: 3.2em;
  background: rgba(0, 0, 0, 0.125);
  border-radius: 1.7em;
  border: 1px solid rgba(255, 255, 255, 0.2);
}

.sub-module-button > span {
  margin: auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-size: 15px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: #ffd200;
  line-height: 1.5;
}

.image-sub-module-button {
  margin-top: 0.2em;
}

.sub-module-content-button,
.sub-module-content-button:hover,
.sub-module-content-button:focus,
.sub-module-content-button:active {
  margin: auto;
  padding: 0;
  width: 15.1em;
  height: 24px;
  background: rgba(255, 255, 255, 0);
  border-radius: 17px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(20px);
  display: flex;

  & > span {
    margin: auto;
    display: inline-block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 13px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: #ffd200;
    line-height: 18px;
  }
}

.arrow {
  /* more triangle */
  height: 7px;
  width: 7px;
  transform: rotate(45deg);
  margin-top: 6px;
  margin-left: 12px;
  margin-right: 12px;
  float: right;
  animation: arrayAnimation infinite 1.5s linear;
  animation-delay: 0.3s;
}

.arrow:before,
.arrow:after {
  content: '';
  position: absolute;
  display: block;
  height: 7px;
  width: 7px;
}

.arrow:before {
  /* top - distance minus border */
  top: 3px;
  left: -4px;
  animation: arrayAnimation infinite 1.5s linear;
}

.arrow:after {
  /* top - distance plus border */
  top: -5px;
  left: 4px;
  animation: arrayAnimation infinite 1.5s linear;
  animation-delay: 0.6s;
}

@keyframes arrayAnimation {
  0% {
    border: 1px solid rgba(255, 210, 0, 1);
    border-width: 1px 1px 0 0;
  }

  20% {
    border: 1px solid rgba(255, 210, 0, 0.45);
    border-width: 1px 1px 0 0;
  }

  40% {
    border: 1px solid rgba(255, 210, 0, 0.15);
    border-width: 1px 1px 0 0;
  }

  60% {
    border: 1px solid rgba(255, 210, 0, 0);
    border-width: 1px 1px 0 0;
  }

  80% {
    border: 1px solid rgba(255, 210, 0, 0.15);
    border-width: 1px 1px 0 0;
  }

  100% {
    border: 1px solid rgba(255, 210, 0, 0.45);
    border-width: 1px 1px 0 0;
  }
}

@theme-color: #111111;
@color: #ffffff;
@gold-color: #ffd200;

.home {
  font-size: 10px;
  width: 100%;
  height: 100%;
  background-color: @theme-color;
  color: @color;
  position: relative;
}

.title {
  color: @color;
}

.chat-body {
  height: calc(100% - 104px);
  overflow-x: hidden;
  overflow-y: scroll;
}

.message-list {
  background-color: @theme-color;
  padding-bottom: 35.6em;

  overflow-x: hidden;
  overflow-y: scroll;
}

.more-module-button {
  text-align: center;
}

.minirefresh-theme-default .minirefresh-downwrap .downwrap-progress,
.minirefresh-theme-default .minirefresh-upwrap .upwrap-progress {
  width: 12px !important;
  height: 12px !important;
  border: 1px solid rgb(255 210 0 / 100%) !important;
  border-bottom-color: #0000 !important;
  margin-right: 0 !important;
}

.no-waiting {
  display: none !important;
}

.toast-tips-content {
  height: 100%;
  display: block;
  // background: rgba(0, 0, 0, 0.4);
  overflow: hidden;
  transition: none;
  animation: unset;

  & > :first-child {
    width: 132px;
    min-height: 122px;
    background: rgba(51, 51, 51, 0.9);
    border-radius: 5px;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(20px);
    margin: auto;
    display: flex;
    padding: 24px 13px;
    margin-top: calc(100vh / 2 - 122px);
    animation: tvb-scale-in 2300ms 1 linear;
  }

  & .ant-message-error {
    margin: auto;
  }

  & .ant-message-error > span {
    width: 100%;
    display: block;
    font-size: 15px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.9);
    line-height: 22px;
  }
}

.tips-icon {
  width: 40px;
  height: 40px;
  opacity: 0.8;
  margin-bottom: 6px;
}

.ant-message {
  height: 100%;
  top: 0;
  animation: unset;

  & > :first-child {
    height: 100%;
  }
}

.minirefresh-wrap {
  position: relative;
  top: 80px;
}

.loading-icon-container {
  width: auto;
  display: none;
  height: 2em;

  & > div {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: auto;
  }

  .loading-icon {
    border-radius: 50%;
    display: inline-block;

    animation: spin 0.3s infinite linear;
    -moz-animation: spin 0.3s infinite linear;
    -webkit-animation: spin 0.3s infinite linear;
    -o-animation: spin 0.3s infinite linear;

    & path {
      stroke: @gold-color;
      fill: @gold-color;
    }
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.message-loading {
  display: flex;
}

.message-modules {
  & div:nth-child(2) > .fade-in-item {
    animation-delay: 0.2s;
  }

  & div:nth-child(3) > .fade-in-item {
    animation-delay: 0.4s;
  }

  & div:nth-child(4) > .fade-in-item {
    animation-delay: 0.6s;
  }

  & div:nth-child(5) > .fade-in-item {
    animation-delay: 0.8s;
  }

  & div:nth-child(6) > .fade-in-item {
    animation-delay: 1s;
  }

  & div:nth-child(7) > .fade-in-item {
    animation-delay: 1.2s;
  }

  & div:nth-child(8) > .fade-in-item {
    animation-delay: 1.4s;
  }

  & div:nth-child(9) > .fade-in-item {
    animation-delay: 1.6s;
  }

  & div:nth-child(10) > .fade-in-item {
    animation-delay: 1.8s;
  }

  & div:nth-child(11) > .fade-in-item {
    animation-delay: 2s;
  }

  & div:nth-child(12) > .fade-in-item {
    animation-delay: 2.2s;
  }
}

@keyframes tvb-scale-in {
  0% {
    transform: scale(0);
  }

  13.04347826% {
    transform: scale(1);
  }

  86.956521739% {
    transform: scale(1);
  }

  100% {
    transform: scale(0);
  }
}

@keyframes tvb-scale-out {
  from {
    transform: scale(1);
  }

  to {
    transform: scale(0.4);
  }
}

@keyframes opacitychange {
  33.33% {
    width: 1em;
    height: 1em;
  }
}

.waiting-card {
  width: 57px;
  height: 3.8em;
  padding: 16px;
  background-color: rgba(255, 255, 255, 0.06);
  border-radius: 2px 16px 16px 16px;
  margin-left: 1em;
  margin-top: 0.6em;
}

.LoaderBalls {
  width: 25px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__item {
    $anim-drt: 0.38s;
    $anim-ease: cubic-bezier(0.6, 0.05, 0.15, 0.95);
    width: 6px;
    height: 6px;
    border-radius: 90%;

    &:nth-child(1) {
      animation: bouncing $anim-drt alternate infinite $anim-ease;
    }

    &:nth-child(2) {
      animation: bouncing $anim-drt calc($anim-drt/4) alternate infinite $anim-ease backwards;
    }

    &:nth-child(3) {
      animation: bouncing $anim-drt calc($anim-drt/2) alternate infinite $anim-ease backwards;
    }
  }
}

@keyframes bouncing {
  0% {
    transform: translate3d(0, 4px, 0) scale(1.1, 0.8);
    background: rgba(255, 210, 0, 0.2);
  }

  100% {
    transform: translate3d(0, -3px, 0) scale(0.95, 1.05);
    background: rgba(255, 210, 0, 0.8);
  }
}

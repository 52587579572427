.connecting-failed {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
  position: fixed;
  z-index: 910;
}

.conntect-content {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.failed-sumary {
  font-size: 17px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 24px;
}

.refresh-button,
.refresh-button:active,
.refresh-button:focus,
.refresh-button:hover {
  margin-top: 1.7143em;
  width: 6.286em;
  height: 2.125em;
  background: #ffd200;
  border-radius: 1.6em;
  border: none;

  display: flex;
  align-items: center;
  justify-content: center;

  & > .refresh-icon {
    width: 16px;
    height: 16px;
    color: #000000;
    margin-top: auto;
    margin-bottom: auto;
  }

  & > span {
    font-size: 16px;
    // font-family: NotoSansCJKtc-Medium, NotoSansCJKtc;
    font-weight: 500;
    color: #1e1e1e;
    line-height: 1.5;
  }
}

.refreshing {
  animation: spin 0.3s infinite linear;
  -moz-animation: spin 0.3s infinite linear;
  -webkit-animation: spin 0.3s infinite linear;
  -o-animation: refreshing 0.3s infinite linear;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@background-color: rgb(255 255 255 / 8%);

.contact-card {
  width: 28.2em;
  padding: 1em 1.2em;
  background-color: @background-color;
  margin: 1.2em 1.2em 0em 1.2em;
  border-radius: 0.2em 1.6em 1.6rem;
}

.contact-title {
  overflow-wrap: break-word;
  color: rgb(255 255 255 / 100%);
  font-size: 16px;
  // font-family: NotoSansCJKtc-Regular;
  text-align: left;
  line-height: 1.5;
  text-overflow: ellipsis;
  margin-top: 0;
  white-space: pre-wrap;
}

.contact-label {
  overflow-wrap: break-word;
  color: rgb(255 255 255 / 100%);
  font-size: 16px;
  // font-family: NotoSansCJKtc-Regular;
  text-align: left;
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0;
  white-space: pre-wrap;
}

.contact-phone,
.contact-email,
.contact-phone:hover,
.contact-email:hover,
.contact-phone:visited,
.contact-email:visited {
  text-decoration: underline;
}

.contact-button-content {
  text-decoration: none;
}

.contact-phone,
.contact-email,
.contact-phone:hover,
.contact-email:hover,
.contact-phone:visited,
.contact-email:visited,
.contact-button-content {
  font-size: 16px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: #ffd200;
  line-height: 1.5;
}

.contact-button {
  width: 18.4em;
  height: 2.429em !important;
  background-color: rgb(255 210 0 / 2%) !important;
  border-radius: 1.7em !important;
  border: 1px solid rgb(255 210 0 / 20%) !important;
  margin-top: 0.77em;
  padding: unset;
}

.contact-more-modal {
  width: 100%;
  height: 100%;

  & > div {
    // height: 12em;
    // background-color: rgb(30 30 30 / 90%);
    background-color: rgb(30 30 30 / 90%);
    border-radius: 0.6em;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    padding: 0;

    & div {
      font-size: 17px;
      // font-family: Noto Sans;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.9);
      line-height: 24px;
      border-radius: 0.375em;
      background-color: unset;
    }

    & .ant-modal-body {
      padding: 0px;
      // height: 6.588235294117647em;
      // display: flex;
      margin: auto;
      border-bottom: solid 1px rgba(0, 0, 0, 0.3);
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;

      & p {
        padding: 1.882352941176471em 1.882352941176471em 1.823529411764706em 1.882352941176471em;
        margin: auto;
        text-align: center;
        display: block;
      }
    }

    & .ant-modal-footer {
      border-top: none;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0px;

      & button {
        margin: auto;
        height: auto;
        width: 50%;
        padding: 1.142857142857143em;
        border: none;
        overflow: hidden;
        border-radius: 0px;

        & span {
          width: 100%;
          display: block;
          overflow: hidden;
        }

        &:last-child {
          margin-left: 0px !important;
        }
      }

      & > :first-child {
        border-right: 1px solid rgba(0, 0, 0, 0.3);

        & > span {
          font-size: 17px;
          // font-family: NotoSansCJKtc-Medium, NotoSansCJKtc;
          // font-weight: 500;
          color: rgba(255, 255, 255, 0.9);
          line-height: 1.5;
        }
      }

      & > :last-child > span {
        font-size: 17px;
        // font-family: NotoSansCJKtc-Medium, NotoSansCJKtc;
        // font-weight: 500;
        color: #ffd200;
        line-height: 1.5;
      }
    }
  }
}

.func-menu-page {
  width: 100%;
  height: 29.1em;
  overflow-y: scroll;
  padding: 0.8em 0em;

  & .ant-col {
    padding-top: 0.8em;
    display: flex;
  }

  & .icon-card {
    margin: auto;
    background-color: unset;
  }
}

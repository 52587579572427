.sunny {
  width: 100%;
  height: 200px;
  overflow: hidden;
}

@mixin keyframes($animationName) {
  @-webkit-keyframes #{"$animationName"} {
    @content;
  }
  @-moz-keyframes #{$animationName} {
    @content;
  }

  @-ms-keyframes #{$animationName} {
    @content;
  }

  @keyframes #{$animationName} {
    @content;
  }
}

@function randomNum($min, $max) {
  $rand: random();
  $randomNum: $min + floor($rand * (($max - $min) + 1));

  @return $randomNum;
}

$background: #140e35;
$moon-shine: rgba(157, 229, 253, 0.59);
$pink-shine: rgba(253, 157, 238, 0.5);
$sun-shine: rgba(255, 255, 255, 1);

.centered {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}
.night-sky {
  @extend .centered;
  width: 100%;
  height: 200px;

  $width_height: 0;

  .stars {
    width: 170px;
    position: absolute;
    z-index: 999;
    left: 8%;
    top: 40%;
    transform: scale(0.8);

    div:nth-child(1) {
      width: 20px;
      position: relative;
      height: 6px;
      border-radius: 100%;
      background: #fff;
      animation: twinkle-width 1s ease infinite;
    }
    div:nth-child(2) {
      width: 6px;
      height: 20px;
      top: -5px;
      position: absolute;
      background: #fff;
      border-radius: 100%;
      left: 7px;
      top: -7px;
      transform-origin: middle;
      animation: twinkle-height 1s ease infinite;
    }

    div:nth-child(3) {
      background: #fff;
      content: '';
      position: absolute;
      width: 10px;
      height: 10px;
      left: 5px;
      top: -2px;
      border-radius: 100px;
    }
  }

  .stars:nth-child(1) {
    left: 2%;
    top: 45%;
    transform: scale(0.4);
    div {
      animation-delay: 0.5s;
    }
  }

  .stars:nth-child(2) {
    left: 25%;
    top: 10%;
    transform: scale(0.3);
    div {
      animation-delay: 0.5s;
    }
  }

  .stars:nth-child(3) {
    left: 72%;
    top: 56%;
    transform: scale(0.3);
    div {
      animation-delay: 0.5s;
    }
  }
}

.sun-container,
.moon-container {
  @extend .centered;
  height: 200px;
}

.moon-container {
  background:linear-gradient(180deg, #1F194A 20%, #63308A 100%); 
}

.sun,
.moon {
  @extend .moon-container;
  width: 88px;
  height: 88px;
  border-radius: 100%;
  background: #fff;
  top: 70px;
  box-shadow: 0px 20px 50px $moon-shine;
  
  ul li {
    list-style: none;
    background: #fff;
    background: radial-gradient(circle, #fff 0%, transparent 90%);
    position: absolute;
    border-radius: 100%;
    opacity: 0.2;
    transform: scale(1);
    transition: all 0.8s ease;
    -webkit-transition: all 0.8s ease;
    animation: moon-glow 3s ease infinite;
    -webkit-animation: moon-glow 3s ease infinite;
  }
  li:nth-child(1) {
    width: 120%;
    height: 120%;
    left: -8px;
    top: -8px;
  }
  li:nth-child(2) {
    width: 140%;
    height: 140%;
    left: -16px;
    top: -16px;
    animation-delay: 1s;
  }

  li:nth-child(3) {
    width: 160%;
    height: 160%;
    left: -25px;
    top: -25px;
    animation-delay: 2s;
  }
}

.sun {
  background-color: #ffd200;
}

@include keyframes(moon-glow) {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.1);
  }
}

@include keyframes(twinkle-height) {
  0% {
    height: 20px;
    top: -7px;
  }
  50% {
    height: 10px;
    top: -3px;
  }
  100% {
    height: 20px;
    top: -7px;
  }
}

@include keyframes(twinkle-width) {
  0% {
    width: 20px;
    left: 0px;
  }
  50% {
    width: 10px;
    left: 5px;
  }
  100% {
    width: 20px;
    left: 0px;
  }
}

.small-stars {
  width: 100%;
  height: 200px;
  overflow: hidden;
  margin: 0 auto;
  margin-top: 0px;
  position: relative;
}

$small-stars: 200;

@for $i from 0 through $small-stars {
  .small-star:nth-child(#{$i}) {
    $d: random(2);
    width: $d + px;
    height: $d + px;
    z-index: 1;
    background: white;
    border-radius: 50%;
    position: absolute;

    opacity: 0.8;

    margin-left: random(1500) + px;
    margin-top: random(875) + px;

    animation: twinkle-#{$i} 1s linear infinite;
    animation-delay: $i * 0.01s;
  }

  @keyframes twinkle-#{$i} {
    from {
      opacity: 1;
    }

    to {
      opacity: 0.3;
    }
  }
}

.recommend-swiper-slide-item {
  width: auto !important;
  margin: 0.4em;
}

.recommend-button,
.recommend-button:active,
.recommend-button:focus,
.recommend-button:hover {
  font-size: inherit !important;
  background: rgb(255 255 255 / 5%) !important;
  border-radius: 1.7em !important;
  border: 1px solid rgb(255 255 255 / 12%) !important;
  --antd-wave-shadow-color: rgb(255 255 255 / 5%);
}

.recommend-button > span {
  font-size: 14px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgb(255 255 255 / 85%);
  line-height: 1.5;
}

.vertical-recommend {
  width: 30.4em;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 1em;
  padding: 0em 1.6em;
  margin-left: 1.2em;
  margin-top: 1em;

  .vertical-recommend-item {
    width: 100%;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid rgba(255, 255, 255, 0.07);
    padding: 1.4em 0em;
    cursor: pointer;

    & > span {
      font-size: 15px;
      // font-family: Noto Sans;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.85);
      line-height: 1.5;

      max-width: 91.912%;
      white-space: pre-wrap;
      word-break: break-all;
      word-wrap: break-word;

      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
    }

    .direct-icon {
      opacity: 0.85;
    }
  }

  .vertical-recommend-item:last-child {
    border-bottom: none;
  }
}

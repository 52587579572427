@theme-color: #111111;

.message-header {
  width: 100%;
}
.weather-content {
  width: 100%;
  display: flex;
}

.weather-logo {
  display: flex;
  width: 100%;

  margin-top: 44px;
  z-index: 10;

  & > div {
    margin-left: auto;
    margin-right: auto;
  }
}

.svg-pop {
  position: absolute;
  top: -4000px;
  left: -4000px;
}

.gooey {
  max-width: 78%; //  304/390
  background-color: #e7e7e7;
  font-size: 13px;
  line-height: 1.5;
  border: none;
  color: #1e1e1e;
  filter: url('#gooey');
  -webkit-filter: url('#gooey');
  position: relative;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.12);
  cursor: pointer;

  &:focus {
    outline: none;
  }

  .gooey-title {
    z-index: 5;
    max-width: 100%;
    height: 100%;
    background-color: #e7e7e7;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 8px;
    padding: 4px 10px;
  }

  .bubbles {
    position: absolute;
    top: 10px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 90px;
    margin-left: auto;
    margin-right: auto;

    .bubble {
      background-color: #e7e7e7;
      border-radius: 50%;
      position: absolute;
      top: 0;
      left: 0;
      display: block;

      &:nth-child(1) {
        left: 16px;
        width: 13px;
        height: 9px;
        animation: move-1 3.2s infinite;
        -webkit-animation: move-1 3.2s infinite;
      }
      &:nth-child(2) {
        left: 16px;
        width: 14px;
        height: 10px;
        animation: move-1 3.2s infinite;
        -webkit-animation: move-1 3.2s infinite;
        animation-delay: 1.5s;
        -webkit-animation-delay: 1.5s;
      }
    }
  }
}

.weather-logo-bottom {
  height: 3.3em;
  background-color: @theme-color;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
  box-shadow: 0px -2px 8px 0px rgba(0, 0, 0, 0.2);
}

@keyframes move-1 {
  0% {
    transform: translate(0, 40px);
    left: 12%;
  }
  60% {
    transform: translate(0, 25px);
    left: -2%;
    display: block;
  }
  100% {
    transform: translate(0, 0px);
    left: 9%;
  }
}

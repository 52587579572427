.video-swiper-slide-item {
  margin: 0em 0.4em;
  height: 20.8em;
  width: 30.4em;

  & .video-card-video {
    border-radius: 6px 6px 0px 0px;
  }
}

.function-swiper-slide-item {
  margin: 0em 0.4em;
  margin-bottom: 0.6em;
  width: auto !important;
  height: auto;
}

.introduction-card {
  & > .fade-in-item:nth-child(1) {
    animation-delay: 0.3s;
  }

  & > .fade-in-item:nth-child(2) {
    animation-delay: 0.6s;
  }
}

.programmed-card {
  border-radius: 10px;
  width: 20em;
  height: 19.7em;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  cursor: pointer;

  & > .programmed-card-container {
    width: 100%;
    height: 100%;
    position: relative;

    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 10px;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    padding: 0.8em;

    & > .image-card {
      width: 18.4em !important;
      height: 10.4em !important;
      margin-left: unset;
      margin-right: unset;
      position: relative;

      & img {
        opacity: 0.9;
        background-color: unset;
        border: 1px solid rgba(255, 255, 255, 0.05);
      }

      & .image-tips {
        top: 0%;
        left: 1px;
      }
    }

    & > .programme-summary {
      width: 100%;
      margin-top: auto;
      margin-bottom: auto;

      & > .programme-title {
        font-size: 15px;
        // font-family: Noto Sans;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.85);
        line-height: 1.5;

        display: -webkit-box;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      & > .programme-description {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        font-size: 13px;
        // font-family: Noto Sans;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 1.5;
      }
    }

    & > .tc-programme-summary {
      margin: 0.8em 0em;
      height: 6.1em;

      & > .programme-title {
        -webkit-line-clamp: 1;
      }
    }

    & > .en-programme-summary {
      margin: 0.6em 0em;
      height: 6.5em;

      & > .programme-title {
        -webkit-line-clamp: 2;
      }
    }

    & > .programme-last {
      position: absolute;
      bottom: 0.8em;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;

      background: rgba(0, 0, 0, 0.3);
      border-radius: 5px;
      padding: 0.1em 0.8em;
      display: flex;

      & > span {
        display: block;
        margin: auto;

        font-size: 12px;
        // font-family: Noto Sans;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.6);
        line-height: 1.5;
      }
    }
  }
}

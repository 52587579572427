html,
body,
#root {
  margin: 0;
  font-family: "Helvetica Neue", "Helvetica", "Arial", "Noto Sans", "SimHei", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-width: 320px;
  background-color: #111 !important;
  overflow: hidden;
  -webkit-user-drag: none;
  --antd-wave-shadow-color: none;
  --swiper-theme-color: none;
  overscroll-behavior: contain;
}

.minirefresh-wrap {
  min-width: 320px;
}

html {
  height: 100%;
}

body,
#root {
  height: 100%;
  position: absolute;
  top: 0px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.minirefresh-totop,
.downwrap-tips {
  display: none !important;
}

.downwrap-progress {
  margin-top: 5em;
  text-align: center;
}

.ant-btn::after {
  display: none !important;
}

.fade-in-item {
  animation: fadeInItem 0.5s forwards;
  transition: background .2s ease-in;
  transform: translateY(16px);
  opacity: 0;
}


@keyframes fadeInItem {
  100% {
    transform: translatex(0px);
    opacity: 1;
  }
}

.logo-coin {
  perspective: 1000px;
}

.coin {
  transform-style: preserve-3d;
  transform-origin: 0px;
  animation: rotate 2s 1 ease-in-out;
  animation-delay: 1s;
  transform: rotate3d(0, 1, 0, 90deg) translate3d(0, 0, 0);
}
@keyframes rotate {
  0% {
    transform: rotate3d(0, 1, 0, 0deg) translate3d(0, 0, 0);
  }
  100% {
    transform: rotate3d(0, 1, 0, 2250deg) translate3d(0, 0, 0);
  }
}

figure {
  position: absolute;
  left: calc(50% - 0.4em);
  top: -5px;
  height: 6px;
  width: 5px;
  opacity: 1;
}

figure.coin-top,
figure.coin-bottom {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  overflow: hidden;
}

figure.coin-top img,
figure.coin-bottom img {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  object-fit: cover;
  overflow: hidden;
}

figure.coin-bottom {
  transform: translate3d(-18px, -21px, 0) rotateY(270deg) rotateZ(180deg) rotateX(180deg);
}
figure.coin-top {
  transform: translate3d(-24px, -21px, 0) rotateY(270deg) rotateZ(360deg);
}

[class*='coin-'] {
  background-color: #eee;
}
.coin-1 {
  transform: rotateX(0deg) translateZ(23.5px);
}
.coin-2 {
  transform: rotateX(5deg) translateZ(23.5px);
}
.coin-3 {
  transform: rotateX(10deg) translateZ(23.5px);
}
.coin-4 {
  transform: rotateX(15deg) translateZ(23.5px);
}
.coin-5 {
  transform: rotateX(20deg) translateZ(23.5px);
}
.coin-6 {
  transform: rotateX(25deg) translateZ(23.5px);
}
.coin-7 {
  transform: rotateX(30deg) translateZ(23.5px);
}
.coin-8 {
  transform: rotateX(35deg) translateZ(23.5px);
}
.coin-9 {
  transform: rotateX(40deg) translateZ(23.5px);
}
.coin-10 {
  transform: rotateX(45deg) translateZ(23.5px);
}
.coin-11 {
  transform: rotateX(50deg) translateZ(23.5px);
}
.coin-12 {
  transform: rotateX(55deg) translateZ(23.5px);
}
.coin-13 {
  transform: rotateX(60deg) translateZ(23.5px);
}
.coin-14 {
  transform: rotateX(65deg) translateZ(23.5px);
}
.coin-15 {
  transform: rotateX(70deg) translateZ(23.5px);
}
.coin-16 {
  transform: rotateX(75deg) translateZ(23.5px);
}
.coin-17 {
  transform: rotateX(80deg) translateZ(23.5px);
}
.coin-18 {
  transform: rotateX(85deg) translateZ(23.5px);
}
.coin-19 {
  transform: rotateX(90deg) translateZ(23.5px);
}
.coin-20 {
  transform: rotateX(95deg) translateZ(23.5px);
}
.coin-21 {
  transform: rotateX(100deg) translateZ(23.5px);
}
.coin-22 {
  transform: rotateX(105deg) translateZ(23.5px);
}
.coin-23 {
  transform: rotateX(110deg) translateZ(23.5px);
}
.coin-24 {
  transform: rotateX(115deg) translateZ(23.5px);
}
.coin-25 {
  transform: rotateX(120deg) translateZ(23.5px);
}
.coin-26 {
  transform: rotateX(125deg) translateZ(23.5px);
}
.coin-27 {
  transform: rotateX(130deg) translateZ(23.5px);
}
.coin-28 {
  transform: rotateX(135deg) translateZ(23.5px);
}
.coin-29 {
  transform: rotateX(140deg) translateZ(23.5px);
}
.coin-30 {
  transform: rotateX(145deg) translateZ(23.5px);
}
.coin-31 {
  transform: rotateX(150deg) translateZ(23.5px);
}
.coin-32 {
  transform: rotateX(155deg) translateZ(23.5px);
}
.coin-33 {
  transform: rotateX(160deg) translateZ(23.5px);
}
.coin-34 {
  transform: rotateX(165deg) translateZ(23.5px);
}
.coin-35 {
  transform: rotateX(170deg) translateZ(23.5px);
}
.coin-36 {
  transform: rotateX(175deg) translateZ(23.5px);
}
.coin-37 {
  transform: rotateX(180deg) translateZ(23.5px);
}
.coin-38 {
  transform: rotateX(185deg) translateZ(23.5px);
}
.coin-39 {
  transform: rotateX(190deg) translateZ(23.5px);
}
.coin-40 {
  transform: rotateX(195deg) translateZ(23.5px);
}
.coin-41 {
  transform: rotateX(200deg) translateZ(23.5px);
}
.coin-42 {
  transform: rotateX(205deg) translateZ(23.5px);
}
.coin-43 {
  transform: rotateX(210deg) translateZ(23.5px);
}
.coin-44 {
  transform: rotateX(215deg) translateZ(23.5px);
}
.coin-45 {
  transform: rotateX(220deg) translateZ(23.5px);
}
.coin-46 {
  transform: rotateX(225deg) translateZ(23.5px);
}
.coin-47 {
  transform: rotateX(230deg) translateZ(23.5px);
}
.coin-48 {
  transform: rotateX(235deg) translateZ(23.5px);
}
.coin-49 {
  transform: rotateX(240deg) translateZ(23.5px);
}
.coin-50 {
  transform: rotateX(245deg) translateZ(23.5px);
}
.coin-51 {
  transform: rotateX(250deg) translateZ(23.5px);
}
.coin-52 {
  transform: rotateX(255deg) translateZ(23.5px);
}
.coin-53 {
  transform: rotateX(260deg) translateZ(23.5px);
}
.coin-54 {
  transform: rotateX(265deg) translateZ(23.5px);
}
.coin-55 {
  transform: rotateX(270deg) translateZ(23.5px);
}
.coin-56 {
  transform: rotateX(275deg) translateZ(23.5px);
}
.coin-57 {
  transform: rotateX(280deg) translateZ(23.5px);
}
.coin-58 {
  transform: rotateX(285deg) translateZ(23.5px);
}
.coin-59 {
  transform: rotateX(290deg) translateZ(23.5px);
}
.coin-60 {
  transform: rotateX(295deg) translateZ(23.5px);
}
.coin-61 {
  transform: rotateX(300deg) translateZ(23.5px);
}
.coin-62 {
  transform: rotateX(305deg) translateZ(23.5px);
}
.coin-63 {
  transform: rotateX(310deg) translateZ(23.5px);
}
.coin-64 {
  transform: rotateX(315deg) translateZ(23.5px);
}
.coin-65 {
  transform: rotateX(320deg) translateZ(23.5px);
}
.coin-66 {
  transform: rotateX(325deg) translateZ(23.5px);
}
.coin-67 {
  transform: rotateX(330deg) translateZ(23.5px);
}
.coin-68 {
  transform: rotateX(335deg) translateZ(23.5px);
}
.coin-69 {
  transform: rotateX(340deg) translateZ(23.5px);
}
.coin-70 {
  transform: rotateX(345deg) translateZ(23.5px);
}
.coin-71 {
  transform: rotateX(350deg) translateZ(23.5px);
}
.coin-72 {
  transform: rotateX(355deg) translateZ(23.5px);
}

.coin-day {
  background: linear-gradient(180deg, #77bbfd 0%, #4a9cff 100%);
}

.coin-night-pinkle {
  background: linear-gradient(180deg, #1f194a 0%, #63308a 100%);
}

.coin-night {
  background: linear-gradient(180deg, #150c16 0%, #212e3f 100%);
}

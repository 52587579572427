.button-module {
  width: 100%;
  text-align: center;
}

.program-swiper-slide-item {
  width: 14.8em !important;
  margin: 0.4em;
  margin-bottom: 0em;
}

.program-video-swiper-slide-item {
  height: 11.8em !important;
  width: 15.6em !important;

  & .video-card-video {
    border-radius: 6px;
  }
}

.programme-schedule {
  margin-left: 0.6em;

  & .title-wrapper {
    margin-left: 0.6em;
  }

  & > .swiper-container {
    padding-bottom: 0em;
  }
}

.program-schedule-swiper-slide {
  height: 21.8em !important;
  width: 20.8em !important;
  margin-top: 0.2em;
}

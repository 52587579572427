@background-color: rgb(255 255 255 / 8%);

.swiper-container {
  padding-top: 0.6em;
  padding-bottom: 0.6em;
}

.swiper {
  overflow: visible;
}

.swiper-body {
  // margin-top: 0.8em;
  // margin: 0.8em 1em 0em 0em;

  & .swiper-pagination {
    bottom: -1.4em;

    & > span {
      --swiper-pagination-bullet-inactive-color: #fff;
      --swiper-pagination-color: #fff;
    }
  }
}

.contain-pagination-swiper {
  margin-top: 0em;
  margin-right: 0em;
  margin-bottom: 2em;
}

@background-color: rgba(255, 255, 255, 0.05);

.small-video-card {
  padding: 0.4em 0.4em 0.6em 0.4em;
  height: 11.8em;
  width: 15.6em;

  & .ant-image,
  & .self-poster,
  & .vjs-tech,
  & .image-background {
    overflow: hidden;
    border-radius: 0.6em;
  }
  & .vjs-big-play-button {
    margin-top: -0.8em;
    margin-left: -0.8em;
  }

  & .vjs-big-play-button,
  & .vjs-big-play-button svg {
    width: 32px;
    height: 32px;
  }
}

.normal-video-card {
  padding: 0em 0em 1em 0em;
  height: 20.8em;
  width: 30.4em;
  background-color: @background-color;
  overflow: hidden;
  border-radius: 0.6em;

  & .ant-image,
  & .self-poster,
  & .vjs-tech,
  & .image-background {
    overflow: hidden;
    border-radius: 6px 6px 0px 0px;
  }
}

.video-card-video {
  width: 100%;
  // height: 85%;

  overflow: hidden;
}

.video-card-summary {
  margin-top: 0.6em;
  & > span {
    display: block;
    font-size: 14px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: #fff;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.normal-video-card-summary {
  margin-left: 1.2em;
  margin-right: 1.2em;
}

.small-video-card-summary {
  & > span {
    color: rgba(255, 255, 255, 0.85);
  }
}

@background-color: rgb(255 255 255 / 8%);

.image-card {
  background-color: @background-color;
  border-radius: 0.6em;
  width: 14.8em !important;
  height: 8.3em !important;
  margin: auto;

  & .ant-image {
    width: 100%;
    height: 100%;
  }
}

.image-error {
  width: 100%;
  height: 100%;
  display: flex;
}

.image-error-msg {
  margin: auto;
  text-align: center;
  font-size: 13px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
  padding: 0.6em;
}

.image-card-image {
  width: 100% !important;
  height: 100% !important;
  border-radius: 0.6em;
  background-color: @background-color;
}

.image-card-summary {
  margin-top: 0.6em;

  & > span {
    font-size: 14px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: #fff;
    line-height: 1.5;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

.image-tips {
  position: absolute;
  top: -1px;
  left: 0%;
  width: 100%;

  & span {
    border-radius: 6px 0px 6px 0px;
    font-size: 11px;
    background-color: #ffd200;
    padding: 2px 6px;
    max-width: 90%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}

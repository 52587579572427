@background-color: rgba(255, 255, 255, 0.03);

.icon-card {
  width: auto;
  height: 100%;
  border-radius: 0.6em;
  width: 8em;

  & .icon-image-container {
    width: 6.4em;
    height: 6.4em;
    margin: auto;
    display: flex;
    position: relative;
  }

  & .icon-image-container-bottom {
    width: 5.6em;
    height: 5.6em;
    position: absolute;
    top: 0.4em;
    left: 0.4em;
    background-position: left;
    background-repeat: no-repeat;
    background-size: 5.6em 5.6em;
    opacity: 0.5;
  }

  & .ant-image {
    width: 6.4em;
    height: 6.4em;
    margin: auto;
    display: flex;

    background: @background-color;
    border-radius: 16px;
    border: 1px solid @background-color;
    backdrop-filter: blur(4px);
    -webkit-backdrop-filter: blur(4px);
  }

  & .jelly-image {
    width: 6.4em;
    height: 6.4em;
  }

  & .jelly-image,
  & .ant-image:hover {
    animation: jelly 0.5s;
    -webkit-animation: jelly 0.5s;
  }

  & .badge-dot {
    width: 7px;
    height: 7px;
    background: #e44242;
    border-radius: 50%;

    position: absolute;
    right: 0.2em;
    top: 0.2em;
  }
}

.icon-card-image {
  width: 5.6em !important;
  height: 5.6em !important;
  margin: auto;
}

.icon-card-summary {
  margin-top: 0.7em;
  width: auto;
  height: auto;
  padding: 0px 1px;

  & > p {
    font-size: 13px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: #fff;
    line-height: 1.4615385;
    white-space: pre-wrap;
    word-wrap: break-word;
  }
}

@keyframes jelly {
  0% {
    transform: scale(1);
  }

  35% {
    transform: scale(1.1375, 0.7375);
    // transform: scale(1.3, 0.7);
  }

  50% {
    transform: scale(0.825, 1.05);
    // transform: scale(0.8, 1.2);
  }

  85% {
    transform: scale(0.9625, 0.9125);
    // transform: scale(1.1, 0.9);
  }

  100% {
    transform: scale(1);
  }
}

@header-background-color: rgba(0, 0, 0, 0);

.header-wraper {
  width: 100%;
  position: absolute;
  top: 0;
  z-index: 999;
}

.page-back-button {
  position: absolute;
  display: flex;
  height: 100%;
  min-height: 4em;
  width: 5em;
  z-index: 9999;
  cursor: pointer;

  & > .return-icon {
    margin-top: auto;
    margin-bottom: auto;
    margin-left: 1em;
    background: rgba(17, 17, 17, 0.2);
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
    border-radius: 100%;

    & > span {
      font-size: 2.4em;
      color: #fff;
    }
  }
}

.header {
  background-color: @header-background-color;
  width: 100%;
  height: 4.8em;
  background: rgb(17 17 17 / 90%);
  display: flex;
  align-items: end;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  border-bottom: 1px solid rgba(255, 255, 255, 0.05);

  & .bo-info {
    height: 100%;
    display: inline-flex;
    align-items: center;
    margin: auto;
  }

  & > .ant-image {
    height: 100%;
    display: inline-flex;
    padding-right: 1em;
    cursor: pointer;
  }
}

// .bo-logo {
//   width: 2.4em !important;
//   height: 2.4em !important;
//   border-radius: 50%;
//   margin-right: 0.5em;
//   border: 1px solid transparent;
//   border-top: 1px solid #000;
//   border-left: 1px solid #000;
// }

// @keyframes rotate {
//   100% {
//     transform: rotate(360deg);
//   }
// }

.bo-name {
  font-size: 1.7em;
  // font-family: NotoSansCJKtc-Medium, NotoSansCJKtc;
  font-weight: 500;
  color: #fff;
  line-height: 1.5;
}

.weather-icon {
  position: absolute;
  display: flex;
  height: 100%;
  z-index: 9999;
  right: 0;
  cursor: pointer;

  & > div {
    margin-top: auto;
    margin-bottom: auto;
  }
}

@color: #ffffff;

@background-color: rgb(255 255 255 / 5%);

.text-module {
  display: flex;
  align-items: center;
  margin-top: 0.6em;
  margin-bottom: 0.6em;
}

.text-content {
  display: inline-block;
  background-color: @background-color;
  border-radius: 0.16667em 1.33333em 1.33333em 1.33333em;
  margin-left: 1.2em;
  max-width: 65.128%; // (304-50)/390

  p {
    width: auto;
    height: auto;
    font-size: 16px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: #fff;
    line-height: 1.5;
    margin: 0.625em 0.75em;
    white-space: pre-wrap;
    word-break: break-all;
    word-wrap: break-word;
    letter-spacing: 0.2px;
  }
}

.text-content-clear {
  max-width: 77.949%; // 304/390

  p {
    width: auto;
    height: auto;
  }
}

.audio-button {
  display: inline-flex;
  margin-left: 0.75em;
  background-color: @background-color;
}

.falldown-card {
  width: 100%;
  height: 0px;
  // height: 100%;
  position: absolute;
  z-index: 5;
}

.emoji-image {
  // width: 100%;
  // height: 100%;
  // max-width: 36px;
  // max-height: 36px;
  width: 36px;
  height: 36px;

  object-fit: cover;
}

.fall {
  display: inline;
  top: -100px;
  left: 0px;

  z-index: 99;
  position: fixed;
}

.falldown {
  display: inline;
  top: -100px;
  left: 0px;

  z-index: 99;
  position: fixed;
  overflow: hidden;
}

@keyframes gif {
  0% {
    background-image: url('/src/assets/png/icon_audio_1@2x.png');
  }

  50% {
    background-image: url('/src/assets/png/icon_audio_2@2x.png');
  }

  80% {
    background-image: url('/src/assets/png/icon_audio@2x.png');
  }

  100% {
    background-image: url('/src/assets/png/icon_audio@2x.png');
  }
}

.audio-player {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  align-items: center;
  cursor: pointer;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;

  .audio-icon {
    display: flex;
    margin: auto;
    width: 24px;
    height: 24px;
  }
}

.audio-playing {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  animation-name: gif;
  animation-duration: 1.5s;
  animation-iteration-count: 1000;
  background-size: 24px 24px;
  background-position: center;
  background-repeat: no-repeat;
  opacity: 0.8;
}

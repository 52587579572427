.connecting-card {
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: 9999;
}

.first-con-card {
  background-color: rgba(17, 17, 17, 1);
}

.recon-card {
  background: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(8px);
  -webkit-backdrop-filter: blur(8px);
}

.conntect-content {
  width: 100%;
  height: auto;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.connecting-image {
  text-align: center;

  & img {
    width: 24em;
    // height: 24em;
  }
}

.loading-process {
  text-align: center;
  margin-top: 2em;
  margin-bottom: 1.8em;
  width: 24em;
  height: 0.6em;

  border-radius: 0.8em;
  background-color: rgba(255, 255, 255, 0.2);
  display: flex;
  align-items: center;

  & > .loading-line {
    width: 0%;
    height: 100%;
    background-color: rgba(255, 210, 0, 1);
    border-radius: 0.8em;
    transition: width 2s;
  }
}

.loading-sumary {
  text-align: center;
  font-size: 17px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.9);
  line-height: 1.5;
}

.close-button {
  width: 3.2em;
  height: 3.2em;
  margin-top: 2.4em;
  background: rgba(255, 255, 255, 0.06);
  border: 1px solid rgba(255, 255, 255, 0.12);
  border-radius: 50%;

  display: flex;
  align-items: center;
  justify-content: center;

  & > .close-icon {
    width: 1.6em;
    height: 1.6em;
    color: #dfdfdf;
  }
}

$small-bp: 640px;
$large-bp: 800px;

$ui-opacity: 0.5;
$ui-color: rgba(#fff, $ui-opacity);
$font: 'Russo One', arial, sans-serif;
$letter-spacing: 0.06em;

.hide {
  opacity: 0;
  visibility: hidden;
}

.remove {
  display: none;
}

.blur {
  filter: blur(12px);
}

.container {
  height: 100%;
  display: none;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  z-index: 950;
  opacity: 0.7;
}

#stage-container {
  // Keep menu blur contained
  overflow: hidden;
  // Let border width add to size
  box-sizing: initial;
  border: 1px solid #222;
  // Allow border to be hidden when fullscreen
  margin: -1px;
}

#canvas-container {
  width: 100%;
  height: 100%;
  transition: filter 0.3s;
  z-index: 999;
  background-color: #222;
  position: fixed;

  canvas {
    position: fixed;
    // Use lighten blend mode so the sky can be shown lighting up behind the canvases.
    mix-blend-mode: lighten;
    z-index: 999;
  }
}

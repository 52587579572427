@background-color: rgba(255, 213, 39, 0.9);

.command-card {
  margin: 1.2em 0;
  height: auto;
  text-align: right;
}

.command-card-content {
  margin-right: 1.2em;
  max-width: 80%;
  padding: 1em 1.2em;
  background: rgba(255, 210, 0, 0.03);
  box-shadow: inset 0px 0px 10px 0px rgba(255, 255, 255, 0.1);
  border-radius: 1.6em 0.2em 1.6em 1.6em;
  display: inline-block;
  text-align: left;
  white-space: pre-wrap;
  word-wrap: break-word;

  & span {
    max-width: 70%;
    font-size: 16px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.85);
    line-height: 1.5;
  }
}

.loading-icon {
  width: 16px;
  height: 16px;
  animation: minirefresh-rotate 0.7s linear infinite;
  -webkit-animation: minirefresh-rotate 0.7s linear infinite;
  border-radius: 50%;
  margin-top: 0.6em;
}

.send-failed-icon {
  width: 16px;
  height: 16px;
  background: #f03030;
  border-radius: 50%;
  margin-right: 0.8em;
}

.weather-icon-container {
  max-width: 10em;
  background-position: left;
  background-repeat: no-repeat;
  padding: 0.3em;

  & > div {
    width: 100%;
    height: 100%;
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    background-color: rgba(255, 255, 255, 0.05);
    display: flex;
    border-radius: 100px 0px 0px 100px;
    overflow: hidden;
  }
}

.weather-icon-container > div > svg {
  width: 24px;
  height: 24px;
}

.weather-icon-img {
  display: inline;
  margin-right: 0.4em;
}

.weather-icon-content {
  font-size: 13px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.5);
  line-height: 1.5;
  margin-right: 0.38462em;
}

.weather-icon-img,
.weather-icon-content {
  margin-top: auto;
  margin-bottom: auto;
}
.clouds-icon {
  background-image: url(/src/assets/png/icon_clouds.png);
}
.lightning-icon {
  background-image: url(/src/assets/png/icon_lightning.png);
}
.rain-icon {
  background-image: url(/src/assets/png/icon_rain.png);
}
.sunny-icon {
  background-image: url(/src/assets/png/icon_sunny.png);
}
.wind-icon {
  background-image: url(/src/assets/png/icon_wind.png);
}
.sunny-moon-icon {
  background-image: url(/src/assets/png/icon_sunny_moon.png);
}

.programme-list-card {
  width: 100%;
  .title-wrapper {
    margin-left: 0px;
    margin-bottom: 8px;
  }

  .programme-list {
    height: 420px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 6px 12px;

    .programmed-item-detail-title {
      width: 197px;
    }

    .programmed-item-detail-tag {
      max-width: 197px;
    }

    .programme-list-button {
      margin: 6px 0px;
      width: 100%;
      height: 36px;
      background: rgba(255, 255, 255, 0.08);
      border-radius: 6px;
      border: unset;
      padding-top: 0px;
      padding-bottom: 0px;

      & > span {
        font-size: 14px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 20px;
        display: inline-flex;
        align-items: center;
      }
    }
  }
}

.programmed-item {
  display: flex;
  padding: 6px 0px;

  .portrait-card-image {
    width: 73px;
    height: 108px;
    border-radius: 4px;
    border: 0.5px solid rgba(255, 255, 255, 0.08);
    box-sizing: border-box;
    object-fit: cover;
  }

  .programmed-item-detail {
    margin-left: 10px;
    position: relative;
    width: 100%;
    min-height: 108px;

    .programmed-item-detail-title {
      // width: 197px;
      font-size: 16px;
      font-weight: 500;
      color: rgba(255, 255, 255, 0.88);
      line-height: 24px;
      margin-bottom: 4px;

      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }

    .programmed-item-detail-year {
      width: 197px;
      font-size: 13px;
      font-weight: 400;
      color: rgba(255, 255, 255, 0.5);
      line-height: 18px;
      letter-spacing: 0.2px;
      margin-bottom: 13px;
    }

    .programmed-item-detail-tags {
      width: 100%;
      // position: absolute;
      bottom: 0px;
      margin-bottom: 4px;

      .ant-space {
        width: 100%;
        // max-height: 45px;
        overflow: hidden;
        line-height: 20px;

        .ant-space-item {
          height: 20px;
          line-height: 20px;
        }
      }

      .programmed-item-detail-tag {
        margin-right: 0px;
        background: rgba(255, 255, 255, 0.06);
        border-radius: 4px;
        box-sizing: border-box;
        border: unset;
        padding: 0 8px;

        font-size: 12px;
        font-weight: 400;
        color: rgba(255, 255, 255, 0.5);
        line-height: 20px;
        display: block;

        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }

      .programmed-item-detail-tag-hide {
        width: 40px;
        height: 20px;
        padding-left: 13px;
        padding-right: 13px;
        display: flex;

        span {
          line-height: 20px;
          display: block;
          width: 2px;
          height: 2px;
          background: #888888;
          margin-top: auto;
          margin-bottom: auto;

          &:nth-child(2) {
            margin-left: 4px;
            margin-right: 4px;
          }
        }
      }
    }
  }
}

.programme-tag-hide-icon {
  position: absolute;
  bottom: -4px;
  right: 0px;
  height: 26px;
  line-height: 26px;
}

.footer {
  width: 100%;
  background-color: rgb(30 30 30 / 90%);
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);
  position: absolute;
  bottom: 0;
  z-index: 900;
  border-top: 1px solid rgba(255, 255, 255, 0.05);
  padding-bottom: 0.2em;
  transition: all 0.2s linear;
  // transform: translateY(29em);
}

.footer-wraper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  height: 5em;
  padding-right: 1.4em;
}

.func-menus-icon {
  margin: auto 1.2em;
  display: flex;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.05);
  border: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;

  & > svg {
    margin: auto;
    opacity: 0.6;
    transition: all 0.2s linear;
  }

  & .badge-dot {
    width: 6px;
    height: 6px;
    background: #e44242;
    border-radius: 50%;

    position: absolute;
    right: 1px;
    top: 1px;
  }
}

.footer-wraper-input {
  width: calc(100% - 32px - 1.2em - 1.2em);
  display: flex;
  justify-content: space-between;
  height: 5em;
}

.footer-input {
  font-size: 16px !important;
  background-color: rgba(255, 255, 255, 0.05) !important;
  height: 2.125em;
  border-radius: 0.375em !important;
  margin-top: auto;
  margin-bottom: auto;
  font-weight: 400;
  line-height: 1.5 !important;
  // font-family: Noto Sans;
  text-indent: 0em;
  padding: 0.375em 0.625em !important;
  border-width: 0 !important;
  color: #fff !important;
  user-select: auto;

  &:hover,
  &:focus,
  &:focus-visible {
    outline: none;
    border-width: 0;
  }

  &::placeholder {
    color: rgba(255, 255, 255, 0.5);
    opacity: 1;
  }
}

.button-wraper {
  width: auto;
  height: 3.2em;
  display: flex;
  margin: auto;
  align-items: center;
}

.message-send-button,
.message-send-button:hover,
.message-send-button:focus,
.message-send-button:active {
  width: auto;
  align-items: center;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 0.857142857142857em;
  margin-right: 0;
  padding: 4px;
  border-radius: 0.428571428571429em;
  background: rgba(0, 0, 0, 0);
  border: 1px solid rgba(255, 255, 255, 0.1);
  color: unset;
  --antd-wave-shadow-color: unset;

  & > span {
    width: 3.54667em;
    height: 100%;
    align-items: center;
    font-size: 15px;
    // font-family: NotoSansCJKtc-Medium, NotoSansCJKtc;
    font-weight: 500;
    color: rgba(255, 255, 255, 0.5);
    line-height: 1.5;
  }
}

.message-ready-button,
.message-ready-button:hover,
.message-ready-button:focus,
.message-ready-button:active {
  background: #ffd200;
  border: 1px solid #ffd200;
  border-color: #ffd200;
  color: #1e1e1e;
  --antd-wave-shadow-color: unset;

  & > span {
    color: #1e1e1e;
  }
}

.message-send-button::after {
  display: none;
}

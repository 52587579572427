.time-shower {
  width: 100%;
  height: 3.046em;
  font-size: 12px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgb(255 255 255 / 40%);
  line-height: 1.5;
  text-align: center;
  padding-top: 0.75em;
  padding-bottom: 0.33333em;
  background-color: #111111;
}

.more-module {
  border: none !important;
  display: block;
  padding: 0px;

  & .fade-in-item:nth-child(2) {
    animation-delay: 0.2s !important;
  }

  & .fade-in-item:nth-child(3) {
    animation-delay: 0.4s !important;
  }

  & .fade-in-item:nth-child(4) {
    animation-delay: 0.6s !important;
  }

  & .fade-in-item:nth-child(5) {
    animation-delay: 0.8s !important;
  }

  & .fade-in-item:nth-child(6) {
    animation-delay: 1s !important;
  }

  & .fade-in-item:nth-child(7) {
    animation-delay: 1.2s !important;
  }

  & .fade-in-item:nth-child(8) {
    animation-delay: 1.4s !important;
  }

  & .fade-in-item:nth-child(9) {
    animation-delay: 1.6s !important;
  }

  & .fade-in-item:nth-child(10) {
    animation-delay: 1.8s !important;
  }

  & .fade-in-item:nth-child(11) {
    animation-delay: 2s !important;
  }

  & .fade-in-item:nth-child(12) {
    animation-delay: 2.2s !important;
  }
}

.more-module-list {
  font-size: unset !important;
}

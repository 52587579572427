.profile-item {
  width: 100%;
  margin-top: 6px;
  margin-bottom: 6px;

  .profile-item-content {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .profile-item-label {
    color: #888888;
    margin-bottom: 2px;
  }

  .profile-item-value {
    color: #dddddd;
  }
}

.profile-card-wrapper {
  .title-wrapper {
    margin-bottom: 8px;
  }
  .profile-card {
    width: 304px;
    background: rgba(255, 255, 255, 0.05);
    border-radius: 10px;
    padding: 6px 12px 0px 12px;
    margin-top: 4px;
    margin-left: 12px;

    .profile-card-footer {
      padding-top: 6px;
      padding-bottom: 4px;

      .profile-change-btn {
        display: block;
        width: 280px;
        height: 36px;
        background: rgba(255, 255, 255, 0.08);
        border-radius: 6px;
        margin-bottom: 8px;
        border: none;

        & span {
          font-size: 14px;
          font-weight: 400;
          color: rgba(255, 255, 255, 0.5);
          line-height: 20px;
        }
      }
    }
  }
}

.pwd-modal {
  .ant-modal {
    max-width: 334px;
  }
  .ant-modal-content {
    border-radius: 20px;
    background-color: #1e1e1e;

    .ant-modal-body {
      padding: 32px;
      backdrop-filter: blur(10px);
      background-color: #1e1e1e;

      .ant-input-affix-wrapper,
      .ant-input-affix-wrapper input {
        background-color: #1e1e1e !important;
      }
    }
  }
}

.profile-pwd-modal {
  .ant-modal-content {
    border-radius: 20px;
    background-color: #1e1e1e;
  }
  .ant-modal-body {
    padding: 32px;
    border-radius: 6px;
    backdrop-filter: blur(10px);
    background-color: #1e1e1e;

    .ant-input-affix-wrapper,
    .ant-input-affix-wrapper input {
      background-color: #1e1e1e !important;
    }

    .ant-form-item {
      margin-bottom: 8px;
      min-height: 57px;

      .ant-form-item-explain {
        min-height: unset;

        .ant-form-item-explain-error {
          margin-top: 4px;
          font-size: 13px;
          font-weight: 400;
          color: #ff6969;
          line-height: 16px;
        }
      }

      .ant-input:not(type='password') {
        padding: 0px 10px;
      }

      .ant-form-item-margin-offset {
        display: none;
      }
    }

    .pwd-submit-btn-item {
      margin-bottom: 16px;
      min-height: unset;

      .pwd-submit-btn {
        padding: 0px;
        width: 100%;
        height: 48px;
        line-height: 48px;
        background: #ffd200;
        border-radius: 6px;
        box-shadow: none !important;
        outline: none !important;
        border: none !important;
        text-shadow: none !important;

        & span {
          font-size: 16px;
          font-weight: 500;
          color: #1e1e1e;
          line-height: 24px;
        }

        & .ant-image {
          width: 100%;
          height: 100%;
          display: flex;

          & img {
            margin: auto;
            width: 24px;
            height: 24px;
          }
        }
      }

      .pwd-submit-btn-disbaled {
        background: #393939;

        & span {
          color: rgba(255, 255, 255, 0.5);
        }
      }
    }

    .pwd-format-label {
      font-size: 13px;
      font-weight: 400;
      color: #bbbbbb;
      line-height: 18px;
      padding-bottom: 16px;
    }

    .pwd-forget-btn-label {
      text-align: center;
      padding-bottom: 0px;

      .pwd-forget-btn {
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: 18px;
        text-decoration: underline;
        height: unset;
        box-shadow: none !important;
        outline: none !important;

        & span {
          font-size: 13px;
          font-weight: 400;
          color: #bbbbbb;
          line-height: 18px;
          text-decoration: underline;
        }
      }
    }

    .ant-input-affix-wrapper {
      border: none;
      border-bottom: 1px solid #393939;
      padding: 0px;
      padding-bottom: 12px;
      box-shadow: none !important;
      outline: none !important;
      border-radius: 0px;

      & input {
        font-size: 16px;
        font-weight: 400;
        color: #dddddd;
        line-height: 24px;
        letter-spacing: 0.2px;
      }

      & .ant-input::placeholder {
        font-size: 16px;
        font-weight: 400;
        color: #888888;
        line-height: 24px;
        letter-spacing: 0.3px;
      }
    }

    .pwd-modal-title,
    .pwd-modal-description {
      width: 100%;
      padding-bottom: 32px;
      font-size: 16px;
      font-weight: 500;
      color: #dddddd;
      line-height: 24px;
      white-space: pre-wrap;
      text-align: center;
    }

    &.pwd-modal-title {
      font-weight: 400;
    }

    .pwd-modal-description {
      padding-bottom: 28px;
      text-align: left;
      font-weight: 400;
    }
  }
  .ant-modal-close {
    right: 4px;
    top: 4px;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
      line-height: 32px;
    }
  }
}

.pwd-result-modal {
  .ant-modal-content {
    border-radius: 6px;
  }
  .ant-modal-body {
    border-radius: 6px;
  }
  .result-modal-body {
    width: 100%;
    height: 100%;
    text-align: center;
    border-radius: 6px;

    .result-modal-body-icon {
      width: 100%;
      display: flex;

      & svg {
        margin: 0 auto;
      }
    }

    .result-modal-label {
      margin-top: 12px;

      & a {
        color: #ffd200;
        text-decoration: underline;
      }
    }

    & p {
      font-size: 16px;
      font-weight: 400;
      color: #dddddd;
      line-height: 24px;
      margin-bottom: 0px;
    }
  }

  .ant-modal-footer {
    border-top: 0.5px solid rgba(255, 255, 255, 0.12);
    padding: 0px;

    & .ant-btn {
      line-height: 56px;
      height: 56px;
      width: 100%;
      padding: 0px;

      & span {
        font-size: 16px;
        font-weight: 500;
        color: #ffd200;
        line-height: 56px;
      }
    }
  }
}

.change-password-form {
  .ant-input-wrapper {
    & :first-child {
      border-radius: 6px 0px 0px 6px;
    }
    & :last-child {
      border-radius: 0px 6px 6px 0px;
    }
    & input {
      background-color: #1e1e1e !important;
      font-size: 16px;
      font-weight: 400;
      color: #dddddd;
      line-height: 24px;
      box-shadow: none !important;
    }

    & input::placeholder {
      color: #888888;
    }

    & input,
    & span {
      border: 1px solid #393939 !important;
    }

    & span {
      background: #393939;

      font-size: 16px;
      font-weight: 400;
      color: #dddddd;
      line-height: 24px;
    }
  }
}

.reset-password-form {
  .ant-form-item {
    min-height: 64px !important;

    .ant-input {
      padding: 10px !important;
      height: 42px;
    }

    &.email-input-item {
      .ant-input {
        height: 44px;
      }
      .ant-input-group-wrapper-status-error {
        .ant-input-group {
          border-radius: 6px;
          border: 1px solid #ff6969;
          input,
          span {
            border: 0 solid #393939 !important;
          }
        }
      }
    }
  }

  .pwd-submit-btn-item {
    min-height: 48px !important;
  }

  .email-input-item {
    margin-bottom: 6px !important;
  }

  .pwd-format-label {
    text-align: left;
  }
}

.forget-input {
  display: flex;
  border: 1px solid #393939;
  border-radius: 6px;

  &::before {
    content: var(--before-content);

    height: 100%;
    background: #393939;
    // border-radius: 0px 6px 6px 0px;
    padding: 9px 8px;

    font-size: 16px;
    font-weight: 400;
    color: #dddddd;
    line-height: 24px;
  }

  &.ant-input-group-wrapper {
    border-radius: 6px;

    &.ant-input-group-wrapper-status-error {
      border-color: #ff6969;
      overflow: hidden;
    }

    .ant-input-wrapper {
      .ant-input-group-addon {
        border-radius: 0px;
        background: #1e1e1e;
        font-size: 16px;
        font-weight: 400;
        color: #dddddd;
        line-height: 24px;
        border: unset !important;
        padding: 0px;
        padding-left: 10px;
      }

      .ant-input {
        padding-left: 0px !important;
        border: unset !important;
        color: #dddddd !important;
      }
    }
  }
}

// .video-body .vjs-time-control {
//   padding-right: 5em !important;
// }

.video-body .vjs-fluid {
  padding-top: 10%;
}

.self-poster {
  line-height: unset;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  overflow: hidden;
  background-color: rgba(255, 255, 255, 0.4);

  & > .ant-image {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    backdrop-filter: blur(20px);
    -webkit-backdrop-filter: blur(20px);
  }
}

.image-background {
  width: 100%;
  height: 100%;
  position: absolute;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  backdrop-filter: blur(20px);
  -webkit-backdrop-filter: blur(20px);

  div {
    width: 100%;
    height: 100%;
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.poster-image {
  margin: auto !important;
}

.video-js button,
.video-js .vjs-control-bar div {
  outline: none;
}

.poster-mask {
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  border-radius: 6px 6px 0px 0px;
}

.weather-card {
  background-color: #999999;
  width: 100%;
  height: 180px;
  position: absolute;
  z-index: 0;
  overflow: hidden;
}

.me {
  margin-top: 10px;
  padding-top: 10px;
  border-top: solid 1px #eee;
  text-align: center;
}

.me a {
  text-decoration: none;
  color: #26a69a;
}

.me .name {
  font-size: 17px;
}

.me .handle {
  font-size: 13px;
}

/* Overlay */
.canvas-outer {
  position: relative;
  width: 100%;
  height: 180px;
  margin: 0 auto;
}

.overlay {
  display: flex;
  position: absolute;
  align-items: center;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;

  color: #fff;
}

.location-details {
  text-align: center;
  width: 100%;
}

.city {
  font-size: 19px;
  text-align: center;
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.1);
}

.temp {
  font-size: 21px;
  text-align: center;
  text-shadow: 0px 2px 7px rgba(0, 0, 0, 0.1);
}

/* Canvas */
.canvas {
  width: 100%;
  height: 100%;
}

.night {
  background: linear-gradient(180deg, #150c16 0%, #212e3f 60%);
}

.day {
  background: linear-gradient(180deg, #77bbfd 0%, #4a9cff 100%);
}

.cloud-weather {
  transform: scaleY(-1);

  &.day {
    background: linear-gradient(180deg, #4a9cff 0%, #77bbfd 100%);
  }
  &.night {
    background: linear-gradient(180deg, #212e3f 50%, #150c16 100%);
  }
}

.sunrise {
  background: -webkit-linear-gradient(top, #82a0bc 0%, #aebebe 50%, #e0ceb0 80%, #ffc19c 100%);
  background: linear-gradient(top, #82a0bc 0%, #aebebe 50%, #e0ceb0 80%, #ffc19c 100%);
}

.sunset {
  background: -webkit-linear-gradient(top, #10070c 0%, #2e4557 40%, #91aba8 80%, #ddd8a1 100%);
  background: linear-gradient(top, #10070c 0%, #2e4557 40%, #91aba8 80%, #ddd8a1 100%);
}

/* Controls */
.controls {
  max-width: 600px;
  margin: 0 auto;
  padding-top: 20px;
}

@media (max-width: 600px) {
  .controls {
    padding: 20px 10px;
  }
}

.form-line {
  margin-bottom: 15px;
}

.toggle-form-line {
  display: flex;
}

.form-line .toggle-label {
  flex: 2;
  line-height: 38px;
}

.form-line label {
  display: block;
}

input[type='text'] {
  width: 100%;
}

.radio-button-group {
  display: flex;
}

.radio-button-group input[type='radio'] {
  display: none;
}

.radio-button-group input[type='radio'] + label {
  flex: 1;
  display: block;
  height: 38px;
  padding: 0 12px;
  background-color: #e7e7e7;
  border-color: #ddd;

  // font-weight: $regular;
  text-align: center;
  line-height: 38px;

  cursor: pointer;
}

.radio-button-group input[type='radio']:checked + label {
  background-color: #00e676;
  // font-weight: $medium;
  color: #fff;
}

input[type='checkbox'] {
  display: none;
}

input[type='checkbox'] + label {
  display: block;
  position: relative;
  height: 38px;
  width: 76px;
  background-color: #ddd;
  border-radius: 19px;
  cursor: pointer;

  transition: background 200ms;
}

input[type='checkbox']:checked + label {
  background-color: #00e676;
}

input[type='checkbox'] + label::after {
  content: '';
  position: absolute;
  width: 34px;
  height: 34px;
  margin: 2px;
  border-radius: 50%;
  background-color: #fff;

  transition: transform 200ms;
}

input[type='checkbox']:checked + label::after {
  transform: translate3d(38px, 0, 0);
}

@background-color: rgb(255 255 255 / 5%);

.button-card,
.button-card:hover,
.button-card:focus,
.button-card:active {
  display: inline-flex;
  margin: 0.6em 1.2em;
  background-color: @background-color !important;
  border-radius: 1.7em !important;
  border: 1px solid rgb(255 255 255 / 12%) !important;
  color: rgb(255 255 255 / 85%) !important;
  font-size: inherit !important;

  --antd-wave-shadow-color: none;

  & > span {
    font-size: 14px;
    // font-family: Noto Sans;
    font-weight: 400;
    color: rgb(255 255 255 / 85%);
    line-height: 1.5;
    display: flex;
    margin: auto;
  }
}

.right-outlined-arrow {
  margin-top: auto;
  margin-bottom: auto;
}

.down-outlined-arrow {
  transform: rotate(0.25turn);
  margin-top: auto;
  margin-bottom: auto;
}

.more-preogramme-modal {
  animation: tvb-down-up 300ms ;

  .ant-modal {
    max-width: unset;
    margin: auto;
    top: 0px;
    padding: 0px;
  }
  .ant-modal-content,
  .ant-modal-body {
    height: 100%;
    width: 100%;
  }

  .ant-modal-content {
    background-color: unset;
  }

  .ant-modal-body {
    position: relative;
    padding: 0px;
    overflow-y: hidden;
    background-color: rgba(17, 17, 17, 0.2);
    backdrop-filter: blur(40px);
    -webkit-backdrop-filter: blur(40px);

    .progrmame-list-modal-header {
      position: fixed;
      top: 0px;
      z-index: 2000;
      width: 100%;
      height: 44px;
      background: #111111;
      display: flex;
      align-items: center;
      border-bottom: 0.5px solid rgba(255, 255, 255, 0.05);

      & > span {
        width: 100%;
        text-align: center;
        font-size: 16px;
        font-weight: 500;
        color: rgba(255, 255, 255, 0.9);
        line-height: 24px;
      }
    }

    .progrmame-list-modal-background {
      position: absolute;
      top: 44px;
      width: 100%;
      height: 120px;
      background: linear-gradient(180deg, #111111 0%, rgba(17, 17, 17, 0) 100%);
    }

    .progrmame-list-modal-body {
      height: 100%;
      width: 100%;
      padding: 50px 24px 80px 24px;
      overflow-y: auto;
      background-color: var(--body-background-color);

      .progrmame-list-modal-list :last-child {
        padding-bottom: 0px;
      }

      .programmed-item-detail-tag,
      .programmed-item-detail {
        max-width: calc(100vw - 73px - 48px - 10px);
      }

      .progrmame-list-modal-close {
        position: absolute;
        left: 0px;
        bottom: 0px;
        width: 100%;
        height: 80px;
        background: linear-gradient(180deg, rgba(17, 17, 17, 0) 0%, #111111 100%);

        .door-close-icon-container {
          width: 48px;
          height: 48px;
          background: #dddddd;
          backdrop-filter: blur(10px);
          display: flex;
          margin: 16px auto;
          border-radius: 50%;

          & > svg {
            margin: auto;
            display: inline-block;
          }
        }
      }
    }
  }
}

.dokodemo-door {
  margin-left: 0.6em;

  .swiper-slide {
    width: 304px;
    margin-right: 8px;
  }
}

@keyframes tvb-down-up {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0px);
  }
}

@keyframes tvb-up-down {
  from {
    transform: translateY(0px);
  }

  to {
    transform: translateY(100%);
  }
}

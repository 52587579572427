.title-wrapper {
  margin-left: 1.2em;
  margin-bottom: 0.4em;

  & > div {
    width: 80%;
    min-width: 142px;
    height: 1.9em;
    background: rgb(0 0 0 / 0%);
    display: flex;
    align-items: center;
  }
}

.title-before {
  margin-right: 0.8em;
  width: 2px;
  height: 13px;
  margin-top: 0.3em;
  background: linear-gradient(180deg, #ffd200 0%, rgb(255 210 0 / 0%) 100%);
  border-radius: 2px 2px 0 0;
}

.title-content {
  font-size: 14px;
  // font-family: Noto Sans;
  font-weight: 400;
  color: rgb(255 255 255 / 85%);
  line-height: 1.5;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

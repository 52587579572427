@theme-color: #111111;
@gold-color: #ffd200;

.outerScroller {
  position: absolute;
  top: 0px;
  bottom: 0px;
  width: 100%;
  left: 0px;
  overflow: hidden;
}

.scroll {
  width: 100%;
  margin-top: 0px;
  position: absolute;
  left: 0px;
  padding: 0px;
  top: 0px;
  bottom: 0px;
  right: 0px;
  overflow: hidden;
}

.loading-icon-container {
  width: auto;
  display: none;
  margin: 0px;
  height: 30px;

  & > div {
    margin-left: auto;
    margin-right: auto;
    display: block;
    width: auto;
  }

  .loading-icon {
    border-radius: 50%;
    display: inline-block;

    animation: spin 0.3s infinite linear;
    -moz-animation: spin 0.3s infinite linear;
    -webkit-animation: spin 0.3s infinite linear;
    -o-animation: spin 0.3s infinite linear;

    & path {
      stroke: @gold-color;
      fill: @gold-color;
    }
  }
}

.refresh-body {
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
